export const default_env = {
  // 连接相关
  server_ip: '',
  server_port: 22,
  server_user: 'root',
  server_password:'',
  option:'',
  // 样式相关
  lang: "en",
  bg:'#0c0c0c',
  fg:'#cccccc',
  fontFamily: 'Courier New',
  fontSize: 16,
  cursorStyle: 'block',
  cursorBlink: true,
  tCode: true,
};